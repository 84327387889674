.publication-card-div {
  color: rgb(88, 96, 105);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  padding: 2rem;
  cursor: pointer;
  flex: 1 1 25%;
}

.publication-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.publication-name-div {
  display: flex;
  align-items: center;
}

.publication-name {
  text-align: left;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  line-height: 1.2;
  margin: 0px;
}

.publication-description {
  overflow: hidden;
  text-align: left;
  display: -webkit-box;
  font-family: "Google Sans Regular";
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.8rem;
}

.publication-details {
  display: flex;
  flex-direction: row;
}

.publication-creation-date {
  margin: 0;
  font-family: "Google Sans Regular";
  font-weight: 400;
  margin-left: 0px;
  margin-right: auto;
}

@media (max-width: 1200px) {
  .publication-card-div {
    flex: 1 1 40%;
  }
}

@media (max-width: 768px) {
  .publication-card-div {
    flex: 1 1 100%;
  }

  .publication-name {
    font-size: 16px;
  }

  .publication-description {
    font-size: 14px;
  }

  .publication-details {
    flex-direction: column;
  }

  .publication-creation-date {
    font-size: 14px;
  }
}
